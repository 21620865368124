import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdService } from '../../../../services/cd.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Workload } from 'src/app/data/model/workload';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
import * as echarts from 'echarts';
import { ClusterService } from 'src/app/modules/clusters/services/cluster.service';

import { UntypedFormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { ModalSwitchComponent } from './modal-switch/modal-switch.component';
import { ModalUpdateComponent } from './modal-update/modal-update.component';
import { ModalRollbackComponent } from './modal-rollback/modal-rollback.component';
import { Environment } from 'src/app/data/model/environment';


@Component({
  selector: 'app-detail-application',
  templateUrl: './detail-application.component.html',
  styleUrls: ['./detail-application.component.scss'],
  styles: [
    `
      :host >>> .popover {
        font-size: 0.7rem;
      }
      :host >>> .popover > .arrow:after {
        font-size: 0.7rem;
      }

      :host >>> .popover > .popover-header {
        font-size: 0.7rem;
      }
    `,
  ],
})
export class DetailApplicationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('successRateChartElem') successRateChartElem!: ElementRef;
  @ViewChild('latencyChartElem') latencyChartElem!: ElementRef;
  @ViewChild('requestsChartElem') requestsChartElem!: ElementRef;

  projectId: string;
  envId: string;
  appId: string;
  loading: boolean = false;
  application: Workload;
  isToggled = false;
  bsModalRef: BsModalRef;

  successRateChart: any;
  letancyChart: any;
  requestsChart: any;
  cluster: any;
  refreshTimeoutId: any = 1;

  metricTimer = 'minute';
  chart = {
    flow: {
      minute: [], //minute
      hour: [],
      day: [],
    },
    latency: {
      minute: [], //minute
      hour: [],
      day: [],
    },
    succesRate: {
      minute: [], //minute
      hour: [],
      day: [],
    },
  };
  metrics = {
    live: {
      flow: {
        minute: [],
        hour: [],
        day: [],
      },
      latency: {
        minute: [],
        hour: [],
        day: [],
      },
      succesRate: {
        minute: [],
        hour: [],
        day: [],
      },
    },
    staging: {
      flow: {
        minute: [],
        hour: [],
        day: [],
      },
      latency: {
        minute: [],
        hour: [],
        day: [],
      },
      succesRate: {
        minute: [],
        hour: [],
        day: [],
      },
    },
  };

  successRateFormControl: UntypedFormControl;
  latencyFormControl: UntypedFormControl;
  flowFormControl: UntypedFormControl;
  openForm = undefined;
  clusterError = false;

  successRate = {
    type: 'succesRate',
    timer: 'minute',
  };
  latency = {
    type: 'latency',
    timer: 'minute',
  };
  flow = {
    type: 'flow',
    timer: 'minute',
  };

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AppAuthGuardService,
    private cdService: CdService,
    private clusterService: ClusterService,
    private service: AsideExtenderService
  ) {
    this.route.params.subscribe((param: any) => {
      this.appId = param.id;
      this.route.queryParams.subscribe((routeData: any) => {
        this.projectId = routeData.proj;
        this.envId = routeData.env;
        this.openForm = routeData.openForm;
      });
    });
  }

  async onCopyApiGatewayUrl() {
    if (this.cluster) {
      try {
        await navigator.clipboard.writeText(this.cluster?.apiGatewayAddresses);
        this.service.show({
          title: 'Copy success',
          message: 'Link has been copied',
          type: Type.SUCCESS,
          position: Position.TOP,
        });
      } catch (err) {
        this.service.show({
          title: 'Copy error',
          message: err,
          type: Type.ERROR,
          position: Position.TOP,
        });
      }
    }
  }

  ngOnInit(): void {
    this.initFormControl();
    this.getAppData(true);

    if (this.openForm == 'true') {
      this.onUpdate();
    }
  }

  ngOnDestroy(): void {
    if (this.refreshTimeoutId) {
      clearTimeout(this.refreshTimeoutId);
      this.refreshTimeoutId = null;
    }
  }

  getEnvironmentOfWorload() {
    if (this?.application?.clusterName) {
      this.clusterService
        .getEnvironmentById(
          this.application?.projectId,
          this.application?.environmentId
        )
        .subscribe(
          (env: Environment) => {
            this.clusterError = false;
            this.getCluster(env.clusterId);
          },
          (error) => {
            this.clusterError = true;
          }
        );
    }
  }

  getCluster(clusterId: string) {
    if (this?.application?.clusterName) {
      this.clusterService.getByInfo(clusterId).subscribe(
        (data: any) => {
          this.cluster = data;
          this.clusterError = false;
        },
        (error) => {
          this.clusterError = true;
        }
      );
    }
  }
  onGotoGrafanaUrl() {
    let grafUrl = this.cluster.apiGatewayAddresses;
    if (grafUrl) {
      grafUrl = grafUrl.split(',');
      window.open(
        `http://${grafUrl[0]}/d/loki-kube-logs/loki-kubernetes-logs?orgId=1&refresh=1m&var-namespace=${this.application.metadata.namespace}&var-container=All&var-stream=All`,
        '_blank'
      );
    }
  }
  initFormControl(): void {
    this.successRateFormControl = new UntypedFormControl('minute-succesRate');
    this.latencyFormControl = new UntypedFormControl('minute-latency');
    this.flowFormControl = new UntypedFormControl('minute-flow');
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   let successRateChartElem = document.getElementById('successRateChartElem');
    //   let latencyChartElem = document.getElementById('latencyChartElem');
    //   let requestsChartElem = document.getElementById('requestsChartElem');
    //   this.successRateChart = echarts.init(successRateChartElem);
    //   this.letancyChart = echarts.init(latencyChartElem);
    //   this.requestsChart = echarts.init(requestsChartElem);
    // }, 500);
  }

  drawChart(
    chart = null,
    _rawData: any,
    type: 'successRate' | 'latency' | 'requests'
  ): void {
    let title = 'SUCCESS RATE';
    let yAxisColName = 'Rate';

    if (type != 'successRate') {
      title = type.toUpperCase();
    }

    yAxisColName = type === 'latency' ? 'Latency' : 'Requests';

    const option = {
      dataset: [
        {
          id: 'dataset_raw',
          source: _rawData,
        },
        {
          id: 'live_env',
          fromDatasetId: 'dataset_raw',
          transform: {
            type: 'filter',
            config: { dimension: 'Deployment', '=': 'Live' },
          },
        },
        {
          id: 'staging_env',
          fromDatasetId: 'dataset_raw',
          transform: {
            type: 'filter',
            config: { dimension: 'Deployment', '=': 'Staging' },
          },
        },
      ],
      title: {
        text: title,
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        nameLocation: 'middle',
        axisLabel: {
          align: 'right',
          fontSize: 9,
        },
      },
      yAxis: {},
      series: [
        {
          type: 'line',
          datasetId: 'live_env',
          showSymbol: false,
          itemStyle: {
            color: '#437cc2',
          },
          encode: {
            x: 'Hour',
            y: yAxisColName,
            itemName: 'Hour',
            tooltip: [yAxisColName],
          },
        },
        {
          type: 'line',
          datasetId: 'staging_env',
          showSymbol: false,
          itemStyle: {
            color: '#199E58',
          },
          encode: {
            x: 'Hour',
            y: yAxisColName,
            itemName: 'Hour',
            tooltip: [yAxisColName],
          },
        },
      ],
    };

    if (type === 'successRate') {
      option.yAxis['axisLabel'] = {
        fontSize: 9,
        formatter: '{value} %',
        align: 'center',
        margin: 10,
        width: 130,
        nameTextStyle: {},
      };
    } else {
      option.yAxis['axisLabel'] = {
        fontSize: 9,
        align: 'center',
        width: 130,
        nameTextStyle: {},
      };
    }

    setTimeout(() => {
      let chartDom = document.getElementById(type + '-chart');
      if (chart) {
        echarts.dispose(chartDom);
        chart = echarts.init(chartDom);
        chart.setOption(option);
      } else {
        chart = echarts.init(chartDom);
        chart.setOption(option);
        if (type == 'successRate') {
          this.successRateChart = chart;
        }
        if (type == 'latency') {
          this.letancyChart = chart;
        }
        if (type == 'requests') {
          this.requestsChart = chart;
        }
      }
    }, 500);

    // chart.setOption(option);
  }

  getAppData(activateLoader: boolean): void {
    // Si un timeout est déjà en cours, on l'annule
    if (this.refreshTimeoutId) {
      clearTimeout(this.refreshTimeoutId);
      this.refreshTimeoutId = null;
    }

    if (activateLoader) {
      this.loading = true;
    }

    this.cdService
      .getWorkloadById(this.projectId, this.envId, this.appId)
      .subscribe(
        (resp) => {
          this.application = resp;

          this.getEnvironmentOfWorload();

          this.parseMetrics('flow', 'minute');
          this.generateDataByMinute('flow');

          this.parseMetrics('latency', 'minute');
          this.generateDataByMinute('latency');

          this.parseMetrics('succesRate', 'minute');
          this.generateDataByMinute('succesRate');

          // Vérification si un refresh est nécessaire
          const replicasMismatch =
            resp.spec.replicas !== resp?.status?.liveReadyReplicas;
          const strategyMismatch =
            resp.spec.deploymentStrategy !== 'default' &&
            resp?.spec?.replicas !== resp?.status?.stagingReadyReplicas;

          if (replicasMismatch || strategyMismatch) {
            this.refreshTimeoutId = setTimeout(() => {
              this.getAppData(false);
            }, 1000);
          }

          if (activateLoader) {
            this.loading = false;
          }
        },
        (error) => {
          this.service.show({
            title: 'Workload',
            message: error.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.application = null;
          this.loading = false;
        }
      );
  }

  onChangeRate() {
    let parse = this.successRateFormControl.value.split('-');
    if (parse[0] == 'minute') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByMinute(parse[1]);
    }

    if (parse[0] == 'hour') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByHour(parse[1]);
    }

    if (parse[0] == 'day') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByDay(parse[1]);
    }
  }

  onChangeLatency() {
    let parse = this.latencyFormControl.value.split('-');
    if (parse[0] == 'minute') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByMinute(parse[1]);
    }

    if (parse[0] == 'hour') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByHour(parse[1]);
    }

    if (parse[0] == 'day') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByDay(parse[1]);
    }
  }

  onChangeFlow() {
    let parse = this.flowFormControl.value.split('-');
    if (parse[0] == 'minute') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByMinute(parse[1]);
    }

    if (parse[0] == 'hour') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByHour(parse[1]);
    }

    if (parse[0] == 'day') {
      this.parseMetrics(parse[1], parse[0]);
      this.generateDataByDay(parse[1]);
    }
  }

  subMinutes(date, minutes) {
    date.setMinutes(date.getMinutes() - minutes);
    return date;
  }

  addMinutes(date, minutes) {
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  }

  hourToString(date) {
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  dateToString(date) {
    let day = date.getDate().toString().padStart(2, '0');
    let month = date.getMonth() + 1;
    let monthStr = month.toString().padStart(2, '0');
    let year = date.getFullYear();

    return `${year}-${monthStr}-${day}`;
  }

  parseMetrics(type: any, timer: any) {
    let app = this.application;
    if (this.application?.metrics) {
      if (this.application?.metrics?.live[type]) {
        this.metrics.live[type][timer] = app?.metrics?.live[type][timer]
          .split(',')
          .reverse();
      }
      if (this.application?.spec?.deploymentStrategy != 'default') {
        if (this.application?.metrics?.staging[type]) {
          this.metrics.staging[type][timer] = app?.metrics?.staging[type][timer]
            .split(',')
            .reverse();
        }
      }
    }
  }

  generateDataByMinute(type: any) {
    if (this.application?.metrics?.updateDate) {
      let before1 = new Date(this.application?.metrics?.updateDate);
      let time = '';
      before1.setHours(before1.getHours() - 1);

      this.chart[type]['minute'] = [];
      let length = this.metrics?.live[type]['minute'].length;
      for (let index = 0; index < length; index++) {
        if (index == 0) {
          time = this.hourToString(before1);
          this.chart[type]['minute'].push(['Rate', 'Deployment', 'Hour']);
        } else {
          if (index == length - 1) {
            time = this.hourToString(
              new Date(this.application?.metrics?.updateDate)
            );
          } else {
            time = this.hourToString(this.addMinutes(before1, 1));
          }
        }

        if (this.metrics.live[type]['minute'].length > 0) {
          this.chart[type]['minute'].push([
            parseInt(this.metrics.live[type]['minute'][index]),
            'Live',
            time,
          ]);
        }

        if (this.application?.spec?.deploymentStrategy != 'default') {
          if (this.metrics.staging[type]['minute'].length > 0) {
            this.chart[type]['minute'].push([
              parseInt(this.metrics.staging[type]['minute'][index]),
              'Staging',
              time,
            ]);
          }
        }
      }

      if (type == 'succesRate') {
        this.drawChart(
          this.successRateChart,
          this.chart[type]['minute'],
          'successRate'
        );
      }
      if (type == 'latency') {
        this.drawChart(
          this.letancyChart,
          this.chart[type]['minute'],
          'latency'
        );
      }
      if (type == 'flow') {
        this.drawChart(
          this.requestsChart,
          this.chart[type]['minute'],
          'requests'
        );
      }
    }
  }

  generateDataByHour(type: any) {
    if (this.application?.metrics?.updateDate) {
      let parseDate = new Date(this.application?.metrics?.updateDate);
      let before24 = new Date(this.application?.metrics?.updateDate);
      before24.setHours(before24.getHours() - 23);
      let time = '';

      this.chart[type]['hour'] = [];
      let length = this.metrics?.live[type]['hour'].length;
      for (let index = 0; index < length; index++) {
        if (index == 0) {
          time = this.hourToString(before24);
          this.chart[type]['hour'].push(['Rate', 'Deployment', 'Hour']);
        } else if (index == 1) {
          before24 = this.subMinutes(before24, before24.getMinutes());
          time = this.hourToString(before24);
        } else {
          if (index == length - 1) {
            time = this.hourToString(
              new Date(this.application?.metrics?.updateDate)
            );
          } else {
            time = this.hourToString(this.addMinutes(before24, 60));
          }
        }

        if (this.metrics.live[type]['hour'].length > 0) {
          this.chart[type]['hour'].push([
            parseInt(this.metrics.live[type]['hour'][index]),
            'Live',
            time,
          ]);
        }

        if (this.application?.spec?.deploymentStrategy != 'default') {
          if (this.metrics.staging[type]['hour'].length > 0) {
            this.chart[type]['hour'].push([
              parseInt(this.metrics.staging[type]['hour'][index]),
              'Staging',
              time,
            ]);
          }
        }
      }

      if (type == 'succesRate') {
        this.drawChart(
          this.successRateChart,
          this.chart[type]['hour'],
          'successRate'
        );
      }
      if (type == 'latency') {
        this.drawChart(this.letancyChart, this.chart[type]['hour'], 'latency');
      }
      if (type == 'flow') {
        this.drawChart(
          this.requestsChart,
          this.chart[type]['hour'],
          'requests'
        );
      }
    }
  }

  generateDataByDay(type: any) {
    if (this.application?.metrics?.updateDate) {
      let before7 = new Date(this.application?.metrics?.updateDate);
      let parseDate = new Date(this.application?.metrics?.updateDate);
      let time = '';
      before7.setDate(before7.getDate() - 8);

      let length = this.metrics?.live[type]['day'].length;
      this.chart[type]['day'] = [];
      for (let index = 0; index < length; index++) {
        if (index == 0) {
          time = this.dateToString(before7);
          this.chart[type]['day'].push(['Rate', 'Deployment', 'Hour']);
        } else {
          if (index == length - 1) {
            time = this.dateToString(
              new Date(this.application?.metrics?.updateDate)
            );
          } else {
            time = this.dateToString(this.addMinutes(before7, 1440));
          }
        }

        if (this.metrics.live[type]['day'].length > 0) {
          this.chart[type]['day'].push([
            parseInt(this.metrics.live[type]['day'][index]),
            'Live',
            time,
          ]);
        }

        if (this.application?.spec?.deploymentStrategy != 'default') {
          if (this.metrics.staging[type]['day'].length > 0) {
            this.chart[type]['day'].push([
              parseInt(this.metrics.staging[type]['day'][index]),
              'Staging',
              time,
            ]);
          }
        }
      }

      if (type == 'succesRate') {
        this.drawChart(
          this.successRateChart,
          this.chart[type]['day'],
          'successRate'
        );
      }
      if (type == 'latency') {
        this.drawChart(this.letancyChart, this.chart[type]['day'], 'latency');
      }
      if (type == 'flow') {
        this.drawChart(this.requestsChart, this.chart[type]['day'], 'requests');
      }
    }
  }

  getVersion(version: string): string {
    return this.truncate(version, 10);
  }

  onSwitch(): void {
    const config: any = {
      class: 'modal-dialog-centered',
      initialState: {
        app: this.application,
      },
    };

    this.bsModalRef = this.modalService.show(ModalSwitchComponent, config);
    this.bsModalRef.content.event.subscribe((res) => {
      this.getAppData(true);
    });
  }

  onUpdate(): void {
    this.loading = true;
    this.cdService
      .getWorkloadById(this.projectId, this.envId, this.appId)
      .subscribe(
        (resp) => {
          this.application = resp;
          this.getEnvironmentOfWorload();
          this.parseMetrics('flow', 'minute');
          this.generateDataByMinute('flow');
          this.parseMetrics('latency', 'minute');
          this.generateDataByMinute('latency');
          this.parseMetrics('succesRate', 'minute');
          this.generateDataByMinute('succesRate');
          const config: any = {
            class: 'popup-right-side',
            initialState: {
              app: this.application,
            },
          };
          this.loading = false;
          this.bsModalRef = this.modalService.show(
            ModalUpdateComponent,
            config
          );
          this.bsModalRef.content.event.subscribe((res) => {
            this.getAppData(true);
            window.location.reload();
          });
        },

        (error) => {
          this.service.show({
            title: 'Workload',
            message: error.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.application = null;
          this.loading = false;
        }
      );
  }

  onGoBack(): void {
    this.router.navigate(['/deliver']);
  }

  truncate(str: string, maxlength: number) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  }

  onGotoHost(live: any): void {
    if (live) {
      let protocol = live.tls ? environment.schemas + '://' : 'http://';
      window.open(protocol + live.host, '_blank');
    }
  }

  generateArrayFromHeaders() {
    let array = [];
    let headers = this.application?.spec?.strategySpec?.headers;
    if (headers) {
      let headersKeys = Object.keys(headers);
      let headersValues = Object.values(headers);
      for (let index = 0; index < headersKeys.length; index++) {
        const key = headersKeys[index];
        const value = headersValues[index];
        array.push({
          key: key,
          value: value,
        });
      }
    }

    return array;
  }

  async onCopyUrl(live: any) {
    if (live) {
      try {
        let protocol = live.tls ? environment.schemas + '://' : 'http://';
        await navigator.clipboard.writeText(protocol + live.host);
        this.service.show({
          title: 'Copy success',
          message: 'Link has been copied',
          type: Type.SUCCESS,
          position: Position.TOP,
        });
      } catch (err) {
        this.service.show({
          title: 'Copy error',
          message: err,
          type: Type.ERROR,
          position: Position.TOP,
        });
      }
    } else {
      this.service.show({
        title: 'Copy error',
        message: 'No link currently',
        type: Type.ERROR,
        position: Position.TOP,
      });
    }
  }

  onToggleChange(ev: any): void {
    this.isToggled = ev.target.checked;
    if (this.isToggled) {
    } else {
    }
  }

  isInfo() {
    let val = parseInt(
      this.application?.metrics?.live?.succesRate['minute']?.split(',')[0]
    );
    if (val >= 90) {
      return true;
    } else {
      return false;
    }
  }
  isWarning() {
    let val = parseInt(
      this.application?.metrics?.live?.succesRate['minute']?.split(',')[0]
    );
    if (val < 90 && val >= 75) {
      return true;
    } else {
      return false;
    }
  }
  isDanger() {
    let val = parseInt(
      this.application?.metrics?.live?.succesRate['minute']?.split(',')[0]
    );
    if (val < 75) {
      return true;
    } else {
      return false;
    }
  }

  canUpdate() {
    let canUpdate = false;
    if (
      !this.application.isProd &&
      this.auth?.hasProjectRoles('developer', this.projectId)
    ) {
      canUpdate = true;
    }
    if (
      this.auth?.hasProjectRoles('admin', this.projectId) ||
      this.auth?.hasOrganizationRoles('owner') ||
      this.auth?.hasOrganizationRoles('owner+') ||
      this.auth?.hasOrganizationRoles('super_admin')
    ) {
      canUpdate = true;
    }

    return canUpdate;
  }

  canSwitch() {
    let canSwitch = false;
    if (
      this.auth?.hasOrganizationRoles('owner') ||
      this.auth?.hasOrganizationRoles('owner+') ||
      this.auth?.hasProjectRoles('admin', this.projectId) ||
      this.auth?.hasOrganizationRoles('super_admin')
    ) {
      canSwitch = true;
    }

    return canSwitch;
  }

  canRollback() {
    let canSwitch = false;
    if (
      this.auth?.hasOrganizationRoles('owner') ||
      this.auth?.hasOrganizationRoles('owner+') ||
      this.auth?.hasProjectRoles('admin', this.projectId) ||
      this.auth?.hasOrganizationRoles('super_admin')
    ) {
      canSwitch = true;
    }
    return canSwitch;
  }

  onRollback() {
    const config: any = {
      class: 'modal-dialog-centered',
      initialState: {
        app: this.application,
      },
    };

    this.bsModalRef = this.modalService.show(ModalRollbackComponent, config);
    this.bsModalRef.content.event.subscribe((res) => {
      this.getAppData(true);
    });
  }
}
