<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;" *ngIf="!globalLoader">
  <spinner [status]="status"></spinner>
  <span style="color: #043c66; font-size: 20px;">Loading</span>
</div>
<div class="modal-content msg-content" *ngIf="globalLoader">
  <div class="modal-header msg-head align-items-center">
    <h5 class="modal-title msgtitle" id="exampleModalLabel">
      <button type="button" class="btn btn-primary link-btn mr-4" data-bs-toggle="modal" data-bs-target="#right">
        <i class="bi bi-pencil-fill"></i>
      </button>
      <span>Deploy Application</span>
    </h5>
    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body">
    <form [formGroup]="deployAppForm" class="form-styled">
      <div class="row">
        <div class="col-md-12 col-xl-12 div-env">

          <label> Environment* <i class="fa fa-spinner fa-spin" *ngIf="loadingEnvs"></i> </label>
          <input type="text" (input)="filterEnv('env')" class="form-control" type="text"
            placeholder="Select environment.." formControlName="env" (click)="myFunction('env')"
            style="margin: 0px !important;font-size: 1rem !important;">
            <ul *ngIf="!isEnvSelected && defaultOptions.length > 0 && inputName === 'env'" class="ulStyled" style="width: 100% !important;">
              <li *ngFor="let option of defaultOptions" (click)="selectOption(option, 'env')">
                {{ option?.name }}
              </li>
            </ul>
            <ul *ngIf="!isEnvSelected && showAddButton && inputName === 'env'" class="ulStyledNewEnv" (click)="addNewEnv()" style="width: 100% !important;">
              <li>Create new Environment : &nbsp;{{deployAppForm.value.env}}</li>
            </ul>
           <!-- input add cluster if the clusters are more than 1 -->
          <label *ngIf="!isEnvSelected && showAddButton && inputName === 'env' && clusters?.length > 1" style="margin-top: 1rem;"> Cluster </label>
          <select formControlName="cluster" style="margin: 10px 0px;"
            *ngIf="!isEnvSelected && showAddButton && inputName === 'env' && clusters?.length > 1" (ngModelChange)="onSelectChange($event)" >
            <option style="text-transform: capitalize;" *ngFor="let item of clusters; let i =index" [ngValue]="item">
              {{ item.name }}
            </option>
          </select>
          <label style="margin-top:10px; color: #ff6b6b; font-weight: 700; font-size: 11px; margin-bottom: 0px;"
            *ngIf="showAddButton && inputName === 'env' && clusters?.length > 1 && isClusterSelected">Select a cluster please.</label>
            <!-- end input add cluster -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 col-xl-7 div-checkbox mr-auto">
          <div class="switch-on-off d-flex">
            <div class="text mb-5">Exposed</div>
            <label class="switch">
              <input type="checkbox" [formControl]="isExposed" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-12 div-env" *ngIf="isExposed.value">
          <div class="row dom_composed">

            <div class="col-lg-6 col-xl-6">
              <label> Subdomain </label>

              <input [ngClass]="{'input-red': deployAppForm.get('subdomain').invalid, 'gray': sharedDomain  }" type="text" placeholder=""
                formControlName="subdomain" />

            </div>
            <div class="col-lg-6 col-xl-6">
              <label> Domain </label>

              <input type="text" class="form-control" [ngClass]="{'gray': sharedDomain  }" (input)="filterDomain('domain')" placeholder="Select host..." formControlName="domain" [readOnly]="sharedDomain" (click)="myFunction('domain')" style="margin: 0px !important;font-size: 1rem !important;width: 100% !important;">

              <ul *ngIf="!isDomainSelected && defaultDomainOptions.length > 0 && inputName === 'domain'" class="ulStyled" style="position: absolute;">
                <li *ngFor="let option of defaultDomainOptions" (click)="selectOption(option, 'domain')" >
                  {{ option?.host }}
                </li>
              </ul>
              <ul *ngIf="!isDomainSelected && showAddButton  && inputName === 'domain' && !sharedDomain" class="ulStyledNewEnv" (click)="addNewDomain()"style="position: absolute;" >
                <li style="line-height: 1;">Create new host : {{deployAppForm.value.domain}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isExposed.value">
        <div class="col-md-12 col-xl-12 div-env">
          <label> URL </label>

          <span style="color: #2557a6; font-weight: 400;">{{ deployAppForm.get('subdomain').value ? deployAppForm.get('subdomain').value+'.': "" }}{{ deployAppForm.get('domain').value }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-12 div-env">
          <label> Configuration <i class="fa fa-spinner fa-spin" *ngIf="loadingBuildConfig"></i> </label>
          <select class="" formControlName="buildConfig" (change)="onChangeConfigration()">
            <option *ngFor="let item of buildconfigs; let i =index " [ngValue]="item" [disabled]="!canRunOrDeploy(item)"
              [attr.selected]="this.buildconfigs.length == 1 && onChangeConfigration() ? true: null">
              {{ item.name }} {{ !canRunOrDeploy(item) ? '(can\'t used)' : '' }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-xl-4 div-env">
          <label> App Port </label>
          <input type="number" class="formcontrol" placeholder="8080" formControlName="port" />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-5 col-xl-5 mr-auto">
          <button type="button" class="advanced" data-toggle="collapse" data-target="#advance"
            (click)="advanced = !advanced">
            <label>Advanced &nbsp;&nbsp;</label>
            <i class="bi" [ngClass]="advanced == false ? 'bi-chevron-down': 'bi-chevron-up'"></i>
          </button>
        </div>
     </div>
        <div id="advance" class="row collapse">
          <div class="row">
            <div class="col-md-12 col-xl-12 div-env" *ngIf="isExposed.value">
              <label> Custom/Add Prefix Paths </label>
              <input type="text" class="form-control mt-1 ml-0" [ngClass]="{'input-red': deployAppForm.get('ingressPath').invalid }"  placeholder="Prefix" formControlName="ingressPath" />
                <small class="mt-3 text-danger" *ngIf="deployAppForm.get('ingressPath').invalid">Invalid prefix path. Ex: / or /path, /path2/path</small>
                <small class="mt-3" *ngIf="!deployAppForm.get('ingressPath').invalid">If you have more than one prefix path, please add them, separating them with a comma.</small>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-xl-12 div-env">
              <label> Command to execute </label>
              <textarea class="mb-2 p-2" placeholder="Put your commands here" formControlName="postStartCmd"
                rows="2"></textarea>
              <small>Separate commands with a semicolon <strong>;</strong>. Example: php artisan migrate; php artisan
                route:cache;</small>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 col-xl-5 div-checkbox">
              <div class="switch-on-off d-flex">
                <div class="text mb-6">Is a websocket?</div>
                <label class="switch">
                  <input type="checkbox" (change)="onChangeIsASocket($event)" [checked]="isASocket" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      <div formArrayName="environmentVariables" class="col-md-12 col-xl-12 env-variables mt-5 mb-2">
        <div class="row mb-2 pl-3" *ngFor="let envVar of envVariableFormArray.controls; let i = index"
          [formGroupName]="i">
          <div class="col-md-4">
            <div class="title_value mb-2">Key {{ i + 1 }}</div>
            <input type="text" formControlName="key" class="form-input" placeholder="Key" />
            <div class="text-start d-flex justify-content-between">
              <small *ngIf="envVar.get('key').invalid && envVar.get('key').value.length !== 0" style="color: #ff6b6b; margin-top: 5px;line-height: 1.2;"> Invalid key: can't contain spaces or special characters.</small>
              <small *ngIf="envVar.get('key').value.length === 0" style="color: #ff6b6b; margin-top: 5px;line-height: 1.2;"> Required key</small>
              <small *ngIf="envVar.get('key').hasError('duplicate')" style="color: #ff6b6b; font-size: 11px;">Duplicate key</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title_value mb-2">Value {{ i + 1 }}</div>
            <input type="text" formControlName="value" class="form-input" placeholder="Value" />
              <small *ngIf="envVar.get('value').value.length === 0" style="color: #ff6b6b; margin-top: 5px;line-height: 1.2;"> Required value</small>
          </div>
          <div class="col-md-1 pt-4">
            <button (click)="onRemoveEnvironmentVariable(i)" class="btn_delete">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-xl-12 _actions">
            <button class="btn btn_add" (click)="onAddEnvironmentVariable('', '')"
              [disabled]="deployAppForm.get('environmentVariables').invalid">
              <i class="fa fa-plus"></i> Environment Variable
            </button>
            <config-upload (envVars)="loadEnvs($event)"></config-upload>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-12 div-button">
        <button class="create-deploy" (click)="onDeployApp()" [disabled]="loading || deployAppForm.invalid || submitButtonState">
          {{ currentFirstDeploy?.id ? "Update " : "" }}Deploy
        </button>
      </div>
    </form>
  </div>
</div>
