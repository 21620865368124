import { environment } from '../../environments/environment';
import {ConfigService} from '../data/service/config.service';
import {ShareService} from '../data/service/share.service';
import {SocketioService} from '../data/service/socketio.service';
import {OrganizationService} from '../data/service/organization.service';
import {AppAuthGuardService} from '../data/service/app-auth-guard.service';
import {Location} from '@angular/common';
import {LoginService} from '../auth/pages/login/services/login.service';
import { Router } from '@angular/router';
import {Position, Type} from './const';
import {Session} from '../data/model/Session';
// import { Session } from 'inspector';

export function initializer(configService: ConfigService,
                            shareService: ShareService, organizationService: OrganizationService,
                            sockeioService: SocketioService, auth: AppAuthGuardService,
                            loginService: LoginService,
                            router: Router,
                            location: Location): () => Promise<any> {


  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const { local } = environment;
      if (local) {
        const { apiBaseURL } = environment;
        // const { socketURL } = environment;
        const { clientID } = environment;
        shareService.apiBaseURL = apiBaseURL;
        shareService.clientID  = clientID;
        // shareService.socketURL = socketURL;

      } else {
        const values = await configService.getConfig().toPromise();
        shareService.apiBaseURL = values[0];
        shareService.clientID = values[1];
        // shareService.socketURL = values[2];
      }

      try {
        const url = new URL(window.location.href);
        const querySession = url.searchParams.get('session');
        if (querySession) {
          const session = JSON.parse(querySession) as Session;
          shareService.setSession(session);
          session.currentUser = await loginService.getCurrentUser().toPromise();
          shareService.updateSession(session);
          const urlSplit = url.toString().split('?');
          window.location.replace(urlSplit[0]);
          resolve('');
          return;
        }
        if(!auth.isUIAnonymous()){
          let session = shareService.getSession();
           if(session && !auth.isTokenValid(session)){
              await loginService.refresh(session.refresh_token).toPromise().then(async (newSession) => {
                newSession.currentUser = session.currentUser;
                shareService.setSession(newSession);
                newSession.currentUser = await loginService.getCurrentUser().toPromise();
                shareService.updateSession(newSession);
                session = newSession;
              }).catch((error) =>{
                shareService.removeSession();
                router.navigate(['/auth/login/']);
                resolve('');
                return;
              });
           } else if (auth.isTokenValid(session)) {
             session.currentUser = await loginService.getCurrentUser().toPromise();
             shareService.updateSession(session);
           }
           const gitProviderName = url.searchParams.get("provider");
           const currentOrg = url.searchParams.get("currentOrg");
           if((gitProviderName && currentOrg) && location.path() == "" && url.host == environment.baseUrl){
            if(!environment.local && currentOrg !== "default" ){
              const uri = url.toString().replace("://", "://"+currentOrg+".")
              window.location.replace(uri+'&first=true')
              resolve("");
              return
            }
           }
           if (session) {
             sockeioService.setupSocketConnection(session);
             await organizationService.fetchOrganizations(session);
           } else {
             const hash = window.location.hash;
             const urlParams = new URLSearchParams(hash.split('?')[1]);
             if (urlParams.get('redirect')?.length > 0 ) {
               router.navigate(["/auth/login"], {
                 queryParams: { redirect: urlParams.get('redirect') }
               })
             } else {
               router.navigate(["/auth/login"]) 
             }
           }
        } else {
          if (!environment.local) {
            let orgName = window.location.hostname.replace(environment.baseUrl.split(":")[0], "")
            if (orgName.length > 0) {
              orgName = orgName.replace(".", "")
            }
            if(orgName.length > 0 && orgName != "www"){
              await organizationService.getOrganizationInfoByName(orgName).toPromise()
                .then(result => {
                }, er => {
                  router.navigate(['/auth/unknown-organization']);
                });
            }
          }
        }
        resolve('');
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function removeFilterStorage(){
  localStorage.removeItem("currentProject")
  localStorage.removeItem("currentEnv")
  localStorage.removeItem("currentStrategy")
}
