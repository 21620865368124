<div class="col-12" *ngIf="!loadingProvider">
  <div class="row">
    <div class="col-xl-12 col-md-12 header-div">
      <div class="title" style="color: #043c66">
        <span (click)="onBack()" class="button-back">Codes</span><span
          class="routerName">/&nbsp;&nbsp;{{application?.name}}</span>
      </div>

      <button class="run-pipe align-items-center" (click)="runNewPipeline()">
        <i class="bi bi-play-circle-fill" style="font-size: 18px"></i> &nbsp;<span>Run pipeline</span>
      </button>
      <button class="create-app align-items-center" (click)="deployAppInAnEnvironment(null)">
        <i class="bi bi-rocket-fill" style="font-size: 20px"></i> &nbsp;<span>Deploy</span>
      </button>
    </div>
  </div>
</div>
<div class="row mt-1" *ngIf="!loadingProvider">
  <div class="col-md-5">
    <div class="card border-0">
      <div class="card-body">
        <div class="left-part bg-white">
          <div class="p-30 pb-0" *ngIf="!loadingProvider">
            <div class="d-flex mt-2">
              <h5 class="mr-5 w-40">Name</h5>
              <h6 class="mb-0">{{application?.name}}</h6>
            </div>
            <div class="d-flex mt-3">
              <h5 class="mr-5 w-40">Framework</h5>
              <h6 class="mb-0">{{application?.framework}}</h6>
            </div>
            <div class="d-flex mt-3">
              <h5 class="mr-5 w-40">Project</h5>
              <h6 class="mb-0">{{application?.projectName}}</h6>
            </div>
            <div class="d-flex mt-3">
              <h5 class="mr-5 w-40">Git Provider</h5>
              <h6 class="mb-3">{{application?.gitProvider?.charAt(0).toUpperCase() +
                application?.gitProvider?.slice(1)}}</h6>
            </div>
            <div class="d-flex justify-content-between mt-3" style="flex-direction: row; align-items: baseline;"
              *ngIf="['bitbucketserver', 'gitlabserver'].includes(application?.gitProvider)">
              <h5 style="width: 100px;" class="mr-5 w-40">Webhook url</h5>
              <h6 style="width: 150px;" class="mb-2">{{webhookUrl?.slice(0,15)+ '...'}}</h6>
              <div class="link-copy pl-2" style="margin-left: 15px;">
                <span style="cursor: pointer; height: 2rem; padding-top: 10px; white-space: nowrap;"
                  (click)="onCopyUrl(webhookUrl)">
                  copy
                  <i class="px-2 bi bi-back"></i>
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-3" style="flex-direction: row; align-items: baseline;"
              *ngIf="['gitlabserver'].includes(application?.gitProvider)">
              <h5 style="width: 100px;" class="mr-5 w-40">Webhook secret</h5>
              <h6 style="width: 150px;" class="mb-2">{{application?.secret?.slice(0,15)+ '...'}}</h6>
              <div class="link-copy pl-2" style="margin-left: 15px;">
                <span style="cursor: pointer; height: 2rem; padding-top: 10px; white-space: nowrap;"
                  (click)="onCopyUrl(application?.secret)">
                  copy
                  <i class="px-2 bi bi-back"></i>
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-3" style="flex-direction: row; align-items: baseline;"
              *ngIf="['bitbucketserver', 'gitlabserver'].includes(application?.gitProvider)">
              <h5 style="width: 100px;" class="mr-5 w-40">Ssh public key</h5>
              <h6 style="width: 150px;" class="mb-2">{{pipelineData?.sshPublicKey?.slice(0,15)+ '...'}}</h6>
              <div class="link-copy pl-2" style="margin-left: 15px;">
                <span style="cursor: pointer; height: 2rem; padding-top: 10px; white-space: nowrap;"
                  (click)="onCopyUrl(pipelineData?.sshPublicKey)">
                  copy
                  <i class="px-2 bi bi-back"></i>
                </span>
              </div>
            </div>
          <div class="loading-section" *ngIf="loadingProvider">
            Loading provider infos...
          </div>
            <hr style="margin-top: 5px;" />
            <div class="p-30 pb-0" style="display: flex;flex-direction: row;padding-left: 0px !important;">
              <h5 class="">Configuration</h5> <i class="bi bi-plus-circle" *ngIf="canAddOrUpdateOrDeleteConfig()" style="margin-left: auto;" (click)="addBuildConfig()"></i>
            </div>
            <div class="p-30 pb-30" style="padding-left: 0px !important;padding-right: 0px !important;">
              <div class="row" >
                <div class="col-md-12 col-xl-12" >
                    <div class="table_wrapper">
                        <table class="table bconf-table">
                            <tbody *ngFor="let item of buildConfigs">
                              <td style="width: 40%;">
                                <p class="wrapper_tooltip">
                                  <span style="padding: 3px 7px;width: 250px !important;"
                                    class="tooltip info-metrics ">
                                    {{item.name}}
                                  </span>
                                    {{item.name?.length > 15 ? item.name?.slice(0,15)+"..." : item.name}}
                                  </p>
                            </td>
                            <td style="width: 50%;">
                              <p class="wrapper_tooltip">
                              <span style="padding: 3px 7px;width: 250px !important;"
                              *ngIf="item.branchs?.length > 0"
                                class="tooltip info-metrics ">
                                {{item.branchs}}
                              </span>
                                <i class="bi bi-git"></i> &nbsp;
                                {{item.branchs?.length > 15 ? item.branchs?.slice(0,15)+"..." : item.branchs}}
                              </p>
                          </td>
                          <td style="width: 10%;">
                            <p class="action-button">
                                <i *ngIf="canAddOrUpdateOrDeleteConfig(item)" class="bi-pencil-square" style="color: #1CC66D;font-size: 20px;margin-right: 5px;" (click)="openEditModal(item)"></i> &nbsp;&nbsp;
                                <i *ngIf="canAddOrUpdateOrDeleteConfig(item)" class="bi bi-trash" style="color: #fd8484;font-size: 20px;" (click)="openDeleteModal(buildConfigTemplate, item)" ></i></p>
                        </td>
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7">
    <div class="card border-0">
      <div class="card-body">
        <div class="bg-white left-part">
          <div class="padding-continuous">
            <form class="form-group">
              <div class="row">
                <div class="col-md-4">
                  <h5>Continuous integration</h5>
                  <div class="form-check mb-1 padding-Continuous-integration">
                    <input class="form-check-input mr-3" type="checkbox" id="checkbox1" name="checkbox1" checked
                      disabled />
                    <label class="form-check-label checkbox-label mt-1 label-text" for="checkbox1">Checkout</label>
                  </div>

                  <div class="form-check mb-1 padding-Continuous-integration">
                    <input class="form-check-input mr-3" type="checkbox" id="checkbox2" name="checkbox2" checked
                      disabled />
                    <label class="form-check-label checkbox-label mt-1 label-text" for="checkbox2">Build</label>
                  </div>

                  <div class="form-check mb-1 padding-Continuous-integration">
                    <input class="form-check-input mr-3" type="checkbox" id="checkbox3" name="checkbox3" disabled />
                    <label class="form-check-label checkbox-label mt-1 label-text" for="checkbox3">Test</label>
                  </div>

                  <div class="form-check mb-1 padding-Continuous-integration">
                    <input class="form-check-input mr-3" type="checkbox" id="checkbox4" name="checkbox4" checked
                      disabled />
                    <label class="form-check-label checkbox-label mt-1 label-text" for="checkbox4">Scan</label>
                  </div>
                  <div class="form-check mb-1 padding-Continuous-integration">
                    <input class="form-check-input mr-3" type="checkbox" id="checkbox5" name="checkbox5" checked
                      disabled />
                    <label class="form-check-label checkbox-label mt-1 label-text" for="checkbox5">Push</label>
                  </div>
                </div>
                <div class="col-md-8 repo-docker">
                  <h5 for="inputField1" class="form-label">Environments</h5>

                  <!-- <a
                      href="https://github.com/docker-library/official-images"
                      class="my-link sans-serif-font"
                      >use default repo</a
                    > -->
                  <div class="row" *ngFor="let item of deployApp; let i = index">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" style="padding-top: 12px;">
                      {{item?.environmentName}} <i class="fa fa-spinner fa-spin" *ngIf="item.action !== 'Deployed'"></i>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                      <div class="app-deploye-detail py-2" *ngIf="!isArray(item?.spec?.ingress)">
                        <div class="litinfo" *ngIf="item?.spec?.ingress?.enabled">
                          <div class="successRate">
                            <span style="cursor: pointer; white-space: nowrap;" (click)="onGoToUrl(item?.spec?.ingress?.host)">
                              <span class="ingress">{{ item?.spec?.ingress?.host }}</span>
                              <i class="fa fa-external-link"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="app-deploye-detail py-2" *ngIf="isArray(item?.spec?.ingress)">
                        <div class="litinfo" *ngIf="item?.spec?.ingress[0]?.enabled">
                          <div class="successRate">
                            <span style="cursor: pointer; white-space: nowrap;" (click)="onGoToUrl(item?.spec?.ingress[0]?.host)">
                              <span class="ingress">{{ item?.spec?.ingress[0]?.host }}</span>
                              <i class="fa fa-external-link"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12" style="padding-top: 12px;">
                      <i *ngIf="canDeleteDeployement(item)" class="bi bi-trash" style="color: #1CC66D;font-size: 20px;"
                        (click)="onOpenDeleteModal(template, item)"></i>
                    </div>
                  </div>
                  <div class="no-deploy-app" *ngIf="deployApp?.length == 0">
                    Not yet deployed.
                  </div>

                </div>
              </div>
            </form>
          </div>
          <hr />

          <div class="padding-card-text mt-2 wrapper-pipeline" *ngIf="!loadingPipeline">
            <div class="row pipeline mb-2 py-4" *ngFor="let item of pipelineRunDataTable">
              <div class="col-md-6 info-block click" (click)="pipelineLogs(item)">
                <small class="commit-icon mr-3">
                  <i class="fa fa-spinner fa-spin"
                    *ngIf="item.status == '' || (item.status !== 'Succeeded' && !getErrorFailedStatus(item.status))"></i>

                  <i class="fa-solid bi bi-check-circle-fill success-text" *ngIf="item?.status == 'Succeeded'">
                  </i>

                  <i class="fa-solid bi bi-x-circle-fill danger-text" *ngIf="getErrorFailedStatus(item?.status)">
                  </i>
                </small>
                <small class="text-color commit">
                  {{ item?.commitMessage}}
                </small>
              </div>
              <div class="col-md-3 info-block click" (click)="pipelineLogs(item)">
                <small class="text-color">
                  <i class="bi bi-git mr-2"></i>
                  {{item?.branchRef || "default"}}
                </small>
              </div>
              <div class="col-md-2 info-block">
                <small class="text-color created-at text-muted">
                  <i class="bi bi-calendar4 mr-2"></i>
                  {{displayDateForHuman(item?.createdAt)}}
                </small>
              </div>
              <div class="col-md-1 btn-action">
                <button class="text-color rerun" (click)="rerunPipeline(item.projectId, item.id)">
                  <i class="bi bi-arrow-repeat" *ngIf="!(item.id==rerunId)"></i> <i class="fa fa-spinner fa-spin" *ngIf="rerunLoading && item.id==rerunId"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="loading-section" *ngIf="loadingPipeline">
            Loading pipeline jobs...
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;" *ngIf="loadingProvider">
  <spinner [status]="status"></spinner>
  <span style="color: #043c66; font-size: 20px;">App CI/CD loading</span>
</div>
<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="delete-popup">
        <h3 class="heading">Are You Sure?</h3>
        <div class="delete-text">
          Delete Deploy Application <br><br> « <strong>{{ appToDelete?.environmentName.charAt(0).toUpperCase() +
            appToDelete?.environmentName.slice(1) }}</strong> »
        </div>

        <div class="attachment-link d-flex justify-content-between mt-3">
          <button class="btn-delete" type="button" (click)="onDeleteDeployApp()">
            Delete
          </button>
          <button class="btn-cancel" (click)="onCloseDialog()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- modal delete Configuration -->
<ng-template #buildConfigTemplate>
  <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
              (click)="onCloseDialog()">
              <i class="bi bi-x-lg"></i>
          </button>
      </div>

      <div class="modal-body">
          <div class="delete-popup">
              <h3 class="heading">Are You Sure?</h3>
              <div class="delete-text">
                  Delete Configuration <br><br> « <strong>{{ buildConfigToDelete?.name.charAt(0).toUpperCase() +
                      buildConfigToDelete?.name.slice(1) }}</strong> »
              </div>

              <div class="attachment-link d-flex justify-content-between mt-3">
                  <button class="btn-delete" type="button" (click)="deleteBuildConfig()">
                      Delete
                  </button>
                  <button class="btn-cancel" (click)="onCloseDialog()">Cancel</button>
              </div>
          </div>
      </div>
  </div>
</ng-template>
