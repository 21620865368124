<div class="app-detail" *ngIf="application != null">

  <div class="mb-2 d-flex justify-content-between">
    <button type="button" class="back" (click)="onGoBack()">
      <i class="bi-chevron-left"></i> &nbsp; Go back </button>
  </div>
  <header class="d-flex justify-content-between mb-3 header">
    <div class="d-flex align-items-center">
      <div class="app-name">
        Detail application
        {{ application?.metadata.name }}
      </div>
      <div class="divider"></div>
      <div class="app-type">
        {{ application?.spec.deploymentStrategy }} strategy
      </div>
    </div>

    <div class="_d-flex">
      <button class="btn btn-update mr-2" [disabled]="loading" (click)="onUpdate()" *ngIf="canUpdate()">
        Update
        <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
      </button>
      <button class="btn btn-switch"
        *ngIf="['canary', 'ab/testing', 'blue/green'].includes(application?.spec.deploymentStrategy) && canSwitch()"
        (click)="onSwitch()" [disabled]="loading">
        Go live
      </button>
      <button class="btn btn-rollback ml-2" (click)="onRollback()" [disabled]="loading || !application?.rollbackSpec"
        *ngIf="canRollback() && ['canary', 'ab/testing'].includes(application?.spec.deploymentStrategy)">Rollback</button>
    </div>
  </header>

  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <div class="card deploy-detail-card">
        <div class="card-body">
          <div class="row no-gutters px-1">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="app-title">App Info</div>
            </div>
          </div>

          <div class="row no-gutters px-1 ml-3">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-heading py-2">Cluster</div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-deploye-detail py-2">
                <div *ngIf="cluster?.id">
                  {{ cluster?.name }} ({{ cluster?.type }})
                </div>
                <div *ngIf="!cluster?.id && !clusterError" class="fa fa-spin fa-spinner"></div>
                <div *ngIf="clusterError">NA</div>
              </div>
            </div>
          </div>

          <div class="row no-gutters px-1 ml-3">
            <div class="col-md-3">
              <div class="app-heading py-2">Gateway IP</div>
            </div>
            <div class="col-md-9">
              <div class="app-deploye-detail py-2">
                <div *ngIf="cluster?.id">
                  {{ truncate(cluster?.apiGatewayAddresses, 25) }}
                  <ng-template #popTemplate>
                    {{ cluster?.apiGatewayAddresses }} <i class="fa fa-copy" style="color: #043c66;" (click)="onCopyApiGatewayUrl()"></i>
                  </ng-template>
                  <button popoverTitle="Use this address to configure your DNS. " containerClass="popoverPolice" type="button" placement="auto" [adaptivePosition]="false"
                    class="btn p-0 m-0 position-relative fa fa-info-circle"
                    [popover]="popTemplate"
                    [outsideClick]="true">
                  </button>
                </div>
                <div *ngIf="!cluster?.id && !clusterError" class="fa fa-spin fa-spinner"></div>
                <div *ngIf="clusterError">NA</div>
              </div>
            </div>
          </div>


          <div class="row no-gutters px-1 ml-3">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-heading py-2">Name</div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-deploye-detail py-2">
                {{ application?.metadata.name }}
              </div>
            </div>
          </div>

          <div class="row bg no-gutters px-1 ml-3">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-heading py-2">Project</div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-deploye-detail py-2">
                {{ application?.projectName ? application?.projectName : 'NA' }}
              </div>
            </div>
          </div>

          <div class="row no-gutters px-1 ml-3">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-heading py-2">Environment</div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-deploye-detail py-2">
                {{ application?.environmentName }}
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12"></div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div class="d-flex justify-content-around">
                <div class="app-heading py-2">Status</div>
                <div class="app-deploye-detail py-2">
                  {{ application?.isProd ? "Production" : "Not Production" }}
                </div>
              </div>
            </div>
          </div>

          <div class="row bg no-gutters px-1 ml-3">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-heading py-2">Replicas</div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <div class="app-deploye-detail py-2">
                {{ application?.spec.replicas }}
              </div>
            </div>
          </div>

          <!-- For All -->
          <ng-container>
            <div class="row no-gutters px-1">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="app-title pt-2 d-flex justify-content-between">
                  <span *ngIf="
                      !['default'].includes(
                        application?.spec?.deploymentStrategy
                      )
                    ">
                    {{ application?.spec?.deploymentStrategy }}
                  </span>
                  <span *ngIf="
                      ['default'].includes(
                        application?.spec?.deploymentStrategy
                      )
                    ">
                    {{
                    application?.spec?.strategySpec?.autoDeploy
                    ? "Auto delivery"
                    : "Manual delivery"
                    }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row no-gutters px-1 ml-3">
              <div class="col-md-3 col-sm-12">
                <div class="app-heading py-2">
                  Live
                  <small>{{ application?.status?.liveReadyReplicas }}/{{ application?.spec.replicas }}</small>
                  <span class="spinner-border text-primary ml-2"
                    style="font-size: 10px; height: 9px; width: 9px; position: relative; left:-1px" role="status"
                    *ngIf="application?.spec?.replicas != application?.status?.liveReadyReplicas">
                    <span class="sr-only">Loading...</span>
                  </span>
                </div>
              </div>
              <div class="col-sm-12 d-flex"
                [class]="{'col-md-4': ['canary'].includes(application?.spec.deploymentStrategy), 'col-md-6': !['canary'].includes(application?.spec.deploymentStrategy)}">
                <div class="app-deploye-detail py-2 mr-3" *ngFor="let l of application?.spec?.strategySpec?.live">
                  <div class="tooltip_detail">
                    <div class="tooltip_text">
                      Version: {{ l?.version }} <br />
                      Image: {{ l?.image }} <br />
                      Container: {{ l?.name }}
                    </div>
                    {{ getVersion(l?.version) }}
                  </div>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12"
                *ngIf="['canary'].includes(application?.spec.deploymentStrategy)">
                <div class="d-flex justify-content-around">
                  <div class="app-heading py-2">
                    {{
                    100 - application?.spec?.strategySpec?.trafficSplitting
                    }}%
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div *ngIf="application?.status?.ingresses?.live">
                  <div class="dropdown" *ngIf="application?.status?.ingresses?.live.length > 1">
                    <button class="dropbtn">
                      <span style="white-space: nowrap">Links ({{ application?.status?.ingresses?.live.length }})</span>
                      <span><i class="bi bi-chevron-down"></i></span>
                    </button>

                    <div class="dropdown-content">
                      <div class="d-flex justify-content-start mb-2"
                        *ngFor="let liv of application?.status?.ingresses?.live">
                        <div class="litinfo">
                          <div class="successRate">
                            <span style="cursor: pointer; white-space: nowrap;" *ngIf="liv"
                              (click)="onGotoHost(liv ? liv: null)">
                              {{ truncate(liv?.host,10) }}
                              <!-- {{liv?.host}} -->
                              <i class="fa fa-external-link"></i></span>
                          </div>
                        </div>
                        <div class="link-copy app-deploye-detail pl-2" style="margin: 0px;">
                          <span style="cursor: pointer; height: 2rem; padding-top: 10px; white-space: nowrap;"
                            (click)="onCopyUrl(liv)">
                            copy
                            <i class="px-2 bi bi-back"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="application?.status?.ingresses?.live.length == 1">
                    <div class="d-flex justify-content-end" *ngFor="let liv of application?.status?.ingresses?.live">
                      <div class="litinfo" *ngIf="!liv.host.includes('*')">
                        <div class="successRate">
                          <span style="cursor: pointer; white-space: nowrap;" *ngIf="liv"
                            (click)="onGotoHost(liv ? liv: null)">
                            {{ truncate(liv?.host,10) }}
                            <!-- {{liv?.host}} -->
                            <i class="fa fa-external-link"></i></span>
                        </div>
                      </div>
                      <div class="link-copy app-deploye-detail pl-2" *ngIf="liv && !liv?.Linkshost?.includes('*')"
                        style="margin: 0px;">
                        <span style="cursor: pointer; height: 2rem; padding-top: 10px;white-space: nowrap;"
                          (click)="onCopyUrl(liv)">
                          copy
                          <i class="px-2 bi bi-back"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end" *ngIf="!application?.status?.ingresses?.live">
                  <div class="litinfo">
                    <div class="successRate">
                      <span>
                        No link
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row bg no-gutters px-1 ml-3"
              *ngIf="['blue/green', 'canary', 'ab/testing'].includes(application?.spec.deploymentStrategy)">
              <div class="col-md-3 col-sm-12">
                <div class="app-heading py-2">
                  Staging
                  <small>{{ application?.status?.stagingReadyReplicas > application?.spec.replicas ? 0 :
                    application?.status?.stagingReadyReplicas}}/{{ application?.spec.replicas }}</small>

                  <span class="spinner-border text-primary ml-2"
                    style="font-size: 10px; height: 9px; width: 9px; position: relative; left:-1px" role="status"
                    *ngIf="reload && application?.spec?.replicas != application?.status?.stagingReadyReplicas">
                    <span class="sr-only">Loading...</span>
                  </span>

                </div>
              </div>
              <div class="col-sm-12 d-flex"
                [class]="{'col-md-4': ['canary'].includes(application?.spec.deploymentStrategy), 'col-md-6': !['canary'].includes(application?.spec.deploymentStrategy)}">
                <div class="app-deploye-detail py-2 mr-3" *ngFor="let s of application?.spec.strategySpec.staging">
                  <div class="tooltip_detail">
                    <div class="tooltip_text">
                      Version: {{ s?.version }} <br />
                      Image: {{ s?.image }} <br />
                      Container: {{ s?.name }}
                    </div>
                    {{ getVersion(s?.version) }}
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-12" *ngIf="['canary'].includes(application?.spec.deploymentStrategy)">
                <div class="d-flex justify-content-around">
                  <div class="app-heading py-2">
                    {{ application?.spec?.strategySpec?.trafficSplitting }}%
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div *ngIf="['blue/green'].includes(application?.spec.deploymentStrategy)">
                  <div *ngIf="application?.status?.ingresses?.staging">
                    <div class="dropdown" *ngIf="application?.status?.ingresses?.staging.length > 1">
                      <button class="dropbtn">
                        <span style="white-space: nowrap">Links ({{ application?.status?.ingresses?.staging.length
                          }})</span>
                        <span><i class="bi bi-chevron-down"></i></span>
                      </button>

                      <div class="dropdown-content">
                        <div class="d-flex justify-content-start mb-2"
                          *ngFor="let stag of application?.status?.ingresses?.staging">
                          <div class="litinfo">
                            <div class="successRate">
                              <span style="cursor: pointer; white-space: nowrap;" *ngIf="stag"
                                (click)="onGotoHost(stag ? stag: null)">
                                <!-- {{ truncate(stag?.host,15) }} -->
                                {{stag?.host}}
                                <i class="fa fa-external-link"></i></span>
                            </div>
                          </div>
                          <div class="link-copy app-deploye-detail pl-2" style="margin: 0px;">
                            <span style="cursor: pointer; height: 2rem; padding-top: 10px; white-space: nowrap;"
                              (click)="onCopyUrl(stag)">
                              copy
                              <i class="px-2 bi bi-back"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="application?.status?.ingresses?.staging.length == 1">
                      <div class="d-flex justify-content-end"
                        *ngFor="let stag of application?.status?.ingresses?.staging">
                        <div class="litinfo" *ngIf="!stag.host.includes('*')">
                          <div class="successRate">
                            <span style="cursor: pointer; white-space: nowrap;" *ngIf="stag"
                              (click)="onGotoHost(stag ? stag: null)">
                              <!-- {{ truncate(stag?.host,15) }} -->
                              {{stag?.host}}
                              <i class="fa fa-external-link"></i></span>
                          </div>
                        </div>
                        <div class="link-copy app-deploye-detail pl-2" *ngIf="stag && !stag.host.includes('*')"
                          style="margin: 0px;">
                          <span style="cursor: pointer; height: 2rem; padding-top: 10px;white-space: nowrap;"
                            (click)="onCopyUrl(stag)">
                            copy
                            <i class="px-2 bi bi-back"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end" *ngIf="!application?.status?.ingresses?.staging">
                    <div class="litinfo">
                      <div class="successRate">
                        <span>
                          No link
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-container>

          <!-- For Canary -->
          <ng-container *ngIf="application?.spec.deploymentStrategy == 'canary'">
            <div class="row no-gutters px-1">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="app-title pt-2">Auto progression</div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-right">
                <div class="app-title app-checked pt-2">
                  <span class="label" style="font-weight: normal">Auto progression</span>
                  <span class="checkbox" *ngIf="
                      application.spec.strategySpec?.autoProgression?.enabled
                    ">
                    <i class="bi bi-check"></i>
                  </span>
                  <span class="checkbox unchecked" *ngIf="
                      !application.spec.strategySpec?.autoProgression?.enabled
                    ">
                    <i class="bi bi-x"></i>
                  </span>
                </div>
              </div>
            </div>

            <div class="row bg no-gutters px-1 ml-3">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="app-heading py-2">Latency</div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="d-flex justify-content-start">
                  <div class="app-deploye-detail py-2">min</div>
                  <div class="pl-2 app-heading py-2">
                    <span *ngIf="
                        application?.spec.strategySpec?.autoProgression?.metrics
                      ">
                      {{
                      application?.spec.strategySpec?.autoProgression
                      ?.metrics["request-duration"]?.min
                      }}s
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="d-flex justify-content-start">
                  <div class="app-deploye-detail py-2">max</div>
                  <div class="app-heading pl-2 py-2">
                    <span *ngIf="
                        application?.spec.strategySpec?.autoProgression?.metrics
                      ">
                      {{
                      application?.spec.strategySpec?.autoProgression
                      ?.metrics["request-duration"]?.max
                      }}s
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="app-deploye-detail py-2"></div>
              </div>
            </div>

            <div class="row no-gutters px-1 ml-3">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="app-heading py-2">Interval</div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="d-flex justify-content-start">
                  <div class="app-deploye-detail py-2">
                    {{
                    application.spec.strategySpec?.autoProgression?.interval
                    }}
                  </div>
                  <div class="pl-2 app-heading py-2"></div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="d-flex justify-content-start">
                  <div class="app-deploye-detail py-2"></div>
                  <div class="app-heading pl-2 py-2"></div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="app-deploye-detail py-2"></div>
              </div>
            </div>

            <div class="row bg no-gutters px-1 ml-3">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="app-heading py-2">Type</div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="d-flex justify-content-start">
                  <div class="app-deploye-detail py-2">
                    {{
                    application.spec.strategySpec?.autoProgression?.steps
                    ? "Step"
                    : "Increment"
                    }}
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="d-flex justify-content-start">
                  <div class="app-heading pl-2 py-2" *ngIf="
                      application.spec.strategySpec?.autoProgression?.steps
                    ">
                    <span *ngFor="
                        let step of application.spec.strategySpec
                          ?.autoProgression?.steps
                      ">{{ step }}%
                    </span>
                  </div>
                  <div class="app-heading pl-2 py-2" *ngIf="
                      application.spec.strategySpec?.autoProgression?.increment
                    ">
                    {{
                    application.spec.strategySpec?.autoProgression?.increment
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row no-gutters px-1 ml-3">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="app-heading py-2">Success rate</div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="d-flex justify-content-start">
                  <div class="app-deploye-detail py-2">min</div>
                  <div class="pl-2 app-heading py-2">
                    <span *ngIf="
                        application?.spec.strategySpec?.autoProgression?.metrics
                      ">
                      {{
                      application?.spec?.strategySpec?.autoProgression
                      ?.metrics["request-success-rate"]?.min
                      }}%
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="d-flex justify-content-start">
                  <div class="app-deploye-detail py-2">max</div>
                  <div class="app-heading pl-2 py-2">
                    <span *ngIf="
                        application?.spec.strategySpec?.autoProgression?.metrics
                      ">
                      {{
                      application?.spec?.strategySpec?.autoProgression
                      ?.metrics["request-success-rate"]?.max
                      }}%
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="app-deploye-detail py-2"></div>
              </div>
            </div>
          </ng-container>

          <!-- For Ab/Testing -->
          <ng-container *ngIf="
              ['ab/testing'].includes(application?.spec.deploymentStrategy)
            ">
            <div class="row no-gutters px-1">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="app-title pt-2 d-flex justify-content-between">
                  <span> Headers </span>
                </div>
              </div>
            </div>

            <div class="row no-gutters px-1 ml-3">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 headers">
                <table>
                  <tbody>
                    <tr>
                      <td class="pb-4" style="font-weight: 700;">Key</td>
                      <td class="pb-4" style="font-weight: 700;">Value</td>
                    </tr>
                    <tr *ngFor="let item of generateArrayFromHeaders()">
                      <td class="th">{{ item.key }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>

          <!-- Update -->
          <div class="row no-gutters px-1 mt-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="d-flex justify-content-end">
                <div>
                  <button type="button" class="btn btn-primary mx-1 update-btn" [disabled]="loading" *ngIf="canUpdate()"
                    (click)="onUpdate()">
                    Update
                    <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
                  </button>
                  <button *ngIf="
                      ['canary', 'ab/testing', 'blue/green'].includes(
                        application?.spec.deploymentStrategy
                      ) && canSwitch()
                    " type="button" class="btn btn-success mx-1 switch-btn" (click)="onSwitch()">
                    Go live
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card deploy-detail-card mt-3">
        <div class="card-body">
          <div class="row no-gutters px-1">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="app-title">Observability </div>
            </div>
          </div>
          <div class="row no-gutters px-1 ml-3">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
              <div class="app-heading py-2">Logging et monitoring</div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <div class="app-deploye-detail py-2">
                <div class="litinfo" style="padding: 10px 10px;">
                  <div class="successRate">
                    <span style="cursor: pointer; white-space: nowrap;" (click)="onGotoGrafanaUrl()">
                      {{"Host path"}}
                      <i class="fa fa-external-link"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 graph">
      <section class="deploy-graph">
        <div class="row">
          <div class="col">
            <div class="card live">
              <div class="card-header d-flex justify-content-between align-items-center">
                <div>LIVE</div>
                <div>
                  <i class="bi bi-eye-fill"></i>
                </div>
              </div>

              <div class="card-body" *ngIf="metrics?.live">
                <div class="row no-gutters">
                  <div class="col border-right">
                    <div class="d-flex flex-column justify-content-center align-items-center p-1 py-4">
                      <div class="top">{{ metrics?.live?.succesRate['minute'].length > 0 ?
                        metrics?.live?.succesRate['minute'][metrics?.live?.succesRate['minute'].length -1] : 0}}%</div>
                      <div class="sale-rate">SUCCESS RATE </div>
                    </div>
                  </div>
                  <div class="col border-right">
                    <div class="d-flex flex-column justify-content-center align-items-center p-1 py-4">
                      <div class="top">{{metrics?.live?.latency['minute'].length > 0 ?
                        metrics?.live?.latency['minute'][metrics?.live?.latency['minute'].length -1]: 0}}</div>
                      <div class="sale-rate">LATENCY / ms </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex flex-column justify-content-center align-items-center p-1 py-4">
                      <div class="top">{{metrics?.live?.flow['minute'].length > 0 ?
                        metrics?.live?.flow['minute'][metrics?.live?.flow['minute'].length -1]: 0}}</div>
                      <div class="sale-rate">REQUESTS / mn</div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="col" *ngIf="!['default'].includes(application?.spec?.deploymentStrategy)">
            <div class="card staging">
              <div class="card-header  d-flex justify-content-between align-items-center">
                <div>STAGING</div>
                <div>
                  <i class="bi bi-eye-fill"></i>
                </div>
              </div>

              <div class="card-body">
                <div class="row no-gutters">
                  <div class="col border-right">
                    <div class="d-flex flex-column justify-content-center align-items-center p-1 py-4">
                      <div class="top">{{ metrics?.staging?.succesRate['minute'].length > 0 ?
                        metrics?.staging?.succesRate['minute'][metrics?.staging?.succesRate['minute'].length -1] : 0 }}%
                      </div>
                      <div class="sale-rate">SUCCESS RATE</div>
                    </div>
                  </div>
                  <div class="col border-right">
                    <div class="d-flex flex-column justify-content-center align-items-center p-1 py-4">
                      <div class="top">{{metrics?.staging?.latency['minute'].length > 0 ?
                        metrics?.staging?.latency['minute'][metrics?.staging?.latency['minute'].length -1] : 0}}</div>
                      <div class="sale-rate">LATENCY / ms </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex flex-column justify-content-center align-items-center p-1 py-4">
                      <div class="top">{{metrics?.staging?.flow['minute'].length > 0 ?
                        metrics?.staging?.flow['minute'][metrics?.staging?.flow['minute'].length -1] : 0}}</div>
                      <div class="sale-rate">REQUESTS / mn</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex mt-3 mb-3">
          <div class="chart-container mr-4">
            <select class="dropdown" [formControl]="successRateFormControl" (change)="onChangeRate()">
              <option value="minute-succesRate">Per Minute</option>
              <option value="hour-succesRate">Per Hour</option>
              <option value="day-succesRate">Per Day</option>
            </select>

            <div class="chart" #successRateChartElem id="successRate-chart" style="width: 100%; height: 300px;"></div>
          </div>

          <div class="chart-container">
            <select class="dropdown" [formControl]="latencyFormControl" (change)="onChangeLatency()">
              <option value="minute-latency">Per Minute</option>
              <option value="hour-latency">Per Hour</option>
              <option value="day-latency">Per Day</option>
            </select>

            <div class="chart" #latencyChartElem id="latency-chart" style="width: 100%; height: 300px;"></div>
          </div>
        </div>

        <div class="chart-container">
          <select class="dropdown" [formControl]="flowFormControl" (change)="onChangeFlow()">
            <option value="minute-flow">Per Minute</option>
            <option value="hour-flow">Per Hour</option>
            <option value="day-flow">Per Day</option>
          </select>

          <div class="chart" #requestsChartElem id="requests-chart" style="width: 100%; height: 300px;"></div>
        </div>
      </section>
    </div>
  </div>
</div>
<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;" *ngIf="loading">
  <spinner [status]="status"></spinner>
  <span style="color: #043c66; font-size: 20px;">App loading</span>
</div>
<view-statement *ngIf="!loading && application == null" [element]="'App Not found'"></view-statement>
