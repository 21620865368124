import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  HashLocationStrategy,
  LocationStrategy,
  Location,
} from '@angular/common';
import { initializer } from './utils/app-init';
import { ConfigService } from './data/service/config.service';
import { ShareService } from './data/service/share.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SocketioService } from './data/service/socketio.service';
import { OrganizationService } from './data/service/organization.service';
import { AppInterceptor } from './data/interceptor/request.interceptor';
import { AppAuthGuardService } from './data/service/app-auth-guard.service';
import { SharedModule } from './shared/shared.module';

import { LoginService } from './auth/pages/login/services/login.service';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { environment } from 'src/environments/environment';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { MainComponent } from './layouts/main/main.component';
import { HeaderComponent } from './layouts/header/header.component';
import { CommandCnoctlComponent } from './layouts/popups/command-cnoctl/command-cnoctl.component';
import { InviteMemberComponent } from './layouts/popups/invite-member/invite-member.component';
import { MessagingAccountComponent } from './layouts/popups/messaging-account/messaging-account.component';
import { UpgradePlanComponent } from './layouts/popups/upgrade-plan/upgrade-plan.component';
import { CheckScreenComponent } from './layouts/popups/check-screen/check-screen.component';
import { AwsRoleDocComponent } from './layouts/popups/aws-role-doc/aws-role-doc.component';
import { ConfigSamlComponent } from './layouts/popups/config-saml/config-saml.component';
import { NoContactOrgComponent } from './layouts/popups/no-contact-org/no-contact-org.component';

import { OAuthModule } from 'angular-oauth2-oidc';
import { AddAppComponent } from './modules/app/add-app/add-app.component';
import { ContextService } from './modules/dashboard/services/context';
import { AppDashboardComponent } from './modules/app/app/app.component';
import { AppDetailsComponent } from './modules/app/app-details/app-details.component';
import { AppLogComponent } from './modules/app/app-log/app-log.component';
import { CreateDeploymentComponent } from './modules/app/create-deployment/create-deployment.component';
import { RegistryComponent } from './modules/app/registry/registry.component';
import { Router } from '@angular/router';
import { BuildConfigComponent } from './modules/app/build-config/build-config.component';
import { RegistryFormModalComponent } from './modules/app/registry/rregistry-form-modal/registry-form-modal.component';
import { RunNewPipelineComponent } from './layouts/popups/run-new-pipeline/run-new-pipeline.component';
import { AddDomainComponent } from './modules/app/domain/add-domain/add-domain.component';
import { DomainComponent } from './modules/app/domain/domain.component';
import { GroupComponent } from './modules/organization/group/group.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CustomTabComponent } from './modules/organization/custom-tab/custom-tab.component';
import { OrganizationComponent } from './modules/organization/organization/organization.component';
import { FormModalComponent } from './modules/organization/organization/form-modal/form-modal.component';
import { FormModalComponent as FormModalGroupComponent } from './modules/organization/group/form-modal/form-modal.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderComponent,
    MainComponent,
    UpgradePlanComponent,
    CommandCnoctlComponent,
    InviteMemberComponent,
    MessagingAccountComponent,
    CheckScreenComponent,
    AwsRoleDocComponent,
    ConfigSamlComponent,
    NoContactOrgComponent,
    AppDashboardComponent,
    AddAppComponent,
    AppDetailsComponent,
    AppLogComponent,
    CreateDeploymentComponent,
    RegistryComponent,
    BuildConfigComponent,
    RegistryFormModalComponent,
    RunNewPipelineComponent,
    DomainComponent,
    AddDomainComponent,
    GroupComponent,
    FormModalGroupComponent,
    CustomTabComponent,
    OrganizationComponent,
    FormModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    SharedModule,
    OAuthModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  providers: [
    BsModalService,
    ContextService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [
        ConfigService,
        ShareService,
        OrganizationService,
        SocketioService,
        AppAuthGuardService,
        LoginService,
        Router,
        Location,
      ],
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
