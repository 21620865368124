<header class="mb-2">
  <nav class="navbar navbar-expand-lg navbar-light bg-light cno-navbar">
    <div class="container-fluid">
      <a class="navbar-brand" (click)="currentMenu = ''" href="#/dashboard"><img
          src="../../../assets/img/news/cloudoor-04.png" class="img-fluid h-100" width="100px" alt="logo" /></a>
      <button class="navbar-toggler" (click)="toggleNavbarCollapsing()" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse justify-content-between" id="navbarSupportedContent"
        [class.collapse]="navbarCollapsed">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 py-2">
          <li class="nav-item hover" (click)="currentMenu = 'apps'" routerLink="/apps"
            [class]="{ active: currentMenu == 'apps' }">
            <a class="nav-link d-flex justify-content-center align-items-center" (click)="currentMenu = 'apps'"
              routerLink="/apps">
              Develop
              <i class="bi bi-code-slash ml-2" style="font-size: 15px;"></i>
            </a>
          </li>

          <li class="nav-item hover" (click)="currentMenu = 'deploy'" aria-current="page" routerLink="/deploy"
            [class]="{ active: currentMenu == 'deploy' }">
            <a class="nav-link d-flex justify-content-center align-items-center" (click)="currentMenu = 'deploy'"
              aria-current="page" routerLink="/deploy">
              Deploy
              <i class="bi bi-rocket-takeoff ml-2" style="font-size: 15px;"></i>
            </a>
          </li>

          <li class="nav-item hover" (click)="currentMenu = 'deliver'" aria-current="page" routerLink="/deliver"
            [class]="{ active: currentMenu == 'deliver' }">
            <a class="nav-link d-flex justify-content-center align-items-center" (click)="currentMenu = 'deliver'"
              aria-current="page" routerLink="/deliver">
              Deliver
              <i class="bi bi-truck ml-2" style="font-size: 15px;"></i>
            </a>
          </li>

          <li class="nav-item px-4" *ngIf="
              auth?.hasOrganizationRoles('super_admin') &&
              shareService?.organizationSelected?.subscriptionType.includes(
                'free'
              )
            ">
            <button type="button" class="btn btn-primary upgrade-btn pe-4" (click)="onUpgradePlan()">
              <i class="bi bi-lightning-fill"></i>
              Upgrade
            </button>
          </li>
        </ul>

        <button type="button" class="btn animated-button" (click)="onGotoApim()">
          Go to APIM &nbsp;<i class="bi bi-box-arrow-up-right"></i>
        </button>

        <div class="container-org">
          <!-- Input de recherche -->
          <div class="search-wrapper">
            <input type="text" [(ngModel)]="organizationSearch" (input)="filterOrganizations()"
              placeholder="Search organization..." (click)="displaySuggesions=true" />
            <i class="fa fa-search search-icon"></i>
          </div>
          <!-- Liste des résultats filtrés -->
          <ul class="suggestions" *ngIf="organizations.length > 0 && displaySuggesions">
            <li *ngFor="let org of organizations" (click)="selectOrganization(org)">
              {{ org.name }}
            </li>
          </ul>
          <ul class="suggestions" *ngIf="organizations.length == 0 && displaySuggesions">
            <li><small class="text-light"><i>Organization not found</i></small></li>
          </ul>
        </div>

        <div class="d-flex topbtn">

          <button type="button" (click)="onCommandCnoctl()" class="btn btn-primary link-btn" data-bs-toggle="modal"
            data-bs-target="#right">
            <i class="bi bi-link-45deg"></i>
          </button>

          <div class="personal-menu">
            <div class="btn-group" dropdown placement="bottom left" (isOpenChange)="onOpenDropdownChange($event)">
              <!-- Bouton avec icône -->
              <button id="button-animated" dropdownToggle type="button"
                [ngClass]="isOpendedDropdown ? 'custom-bg' : 'default-bg'" aria-controls="dropdown-animated">
                <i class="bi bi-person-fill"></i>
              </button>

              <!-- Triangle sous le bouton -->
              <div class="dropdown-arrow dropdown-menu-left" *ngIf="isOpendedDropdown"></div>

              <!-- Contenu du menu -->
              <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                aria-labelledby="button-animated">
                <li role="menuitem">
                  <a class="dropdown-item user" href="#">
                    {{
                    currentUser.username ? currentUser.username : currentUser.displayName
                    }}
                    <br>
                    {{ truncate(currentUser.email, 30) }}
                  </a>
                </li>
                <li role="menuitem" (click)="onUpgradePlan()" style="border-bottom: 1px solid #e3eaf5; color: #1cc66d"
                  *ngIf="
                          auth?.hasOrganizationRoles('super_admin') &&
                          shareService?.organizationSelected.subscriptionType.includes('team')
                        ">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-lightning-charge"></i>
                    <span>Upgrade your plan</span>
                  </a>
                </li>
                <li role="menuitem" (click)="onPorject()">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-box"></i>
                    <span>Projects</span>
                  </a>
                </li>
                <li role="menuitem" (click)="onMembers()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-people"></i>
                    <span>Members</span>
                  </a>
                </li>
                <li role="menuitem" (click)="onCloudProvider()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-cloud"></i>
                    <span>Cloud Providers</span>
                  </a>
                </li>
                <li role="menuitem" (click)="onCluster()">
                  <!-- *ngIf="auth?.hasOrganizationRoles('super_admin') && shareService?.organizationSelected.name == 'default'"> -->
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <img src="../../../../../../assets/img/cluster.png" alt="" srcset="" style="width: 16px" />
                    <span>Clusters</span>
                  </a>
                </li>
                <li role="menuitem" (click)="onRegistryClick()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-pass"></i>
                    <span>Registry</span>
                  </a>

                </li>
                <li role="menuitem" (click)="onDomain()" *ngIf="canShowDomainMenu()">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-key"></i>
                    <span>Domain</span>
                  </a>
                </li>
                <li role="menuitem" (click)="onTag()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-bookmark"></i>
                    <span>Tags</span>
                  </a>
                </li>
                <li role="menuitem" (click)="onManageNotification()" *ngIf="
                          auth?.hasOrganizationRoles('super_admin') &&
                          shareService?.organizationSelected.name == 'default'
                        ">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-bell"></i>
                    <span>Manage Notifications</span>
                  </a>
                </li>

                <li role="menuitem" (click)="onQuota()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <img src="../../../../../../assets/icons/qta.svg" alt="" srcset="" />
                    <span>Quotas</span>
                  </a>
                </li>

                <li role="menuitem" (click)="onConfigSAML()" *ngIf="
                          auth?.hasOrganizationRoles('super_admin') &&
                          shareService?.organizationSelected.subscriptionType.includes(
                            subscriptionType
                          )
                        ">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-lock"></i>
                    <span>Config SSO</span>
                  </a>
                </li>

                <li role="menuitem" (click)="onOrganization()" *ngIf="
                          this.currentUser.email == supportEmail &&
                          shareService?.organizationSelected.name == 'default'
                        ">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-buildings"></i>
                    <span>Organizations</span>
                  </a>
                </li>

                <li role="menuitem" (click)="onDiscordCommunity()" style="border-top: 1px solid #e3eaf5">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-discord"></i>
                    <span>Discord Community</span>
                  </a>
                </li>

                <li role="menuitem" (click)="onSlackCommunity()">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-slack"></i>
                    <span>Slack Community</span>
                  </a>
                </li>
                <li role="menuitem" (click)="onLogout()">
                  <a href="javascript:void(0)" class="dropdown-item menu">
                    <i class="bi bi-power"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
