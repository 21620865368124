import {Injectable} from '@angular/core';
import {Cluster} from '../../../data/model/cluster';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GetRecordsModel} from '../../../data/model/GetRecordsModel';
import { Observable } from 'rxjs';
import {Environment} from '../../../data/model/environment';
import {ShareService} from '../../../data/service/share.service';
import {Metrics} from '../../../data/model/metric';


@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  baseURL = '';
  maxItems: any;
  defaultPage = 0;
  constructor(
    private httpClient: HttpClient,
    public shareService: ShareService
  ) {
    this.baseURL = this.shareService.apiBaseURL + '/api/v1/organizations/';
  }
  getAll(): Observable<GetRecordsModel<Cluster>> {
    return this.httpClient.get<GetRecordsModel<Cluster>>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters?page=' +
        this.defaultPage +
        '&limit=' +
        this.maxItems
    );
  }
  getClusterCpuMetrics(): Observable<Metrics> {
    return this.httpClient.get<Metrics>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/cpu/metrics'
    );
  }
  getClusterMemoryMetrics(): Observable<Metrics> {
    return this.httpClient.get<Metrics>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/memory/metrics'
    );
  }
  getEnvironmentsCpuMetrics(clusterId: string): Observable<Metrics> {
    return this.httpClient.get<Metrics>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        clusterId +
        '/environments/cpu/metrics'
    );
  }
  getEnvironmentsMemoryMetrics(clusterId: string): Observable<Metrics> {
    return this.httpClient.get<Metrics>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        clusterId +
        '/environments/memory/metrics'
    );
  }
  getAllEnvForCluster(
    clusterID: string
  ): Observable<GetRecordsModel<Environment>> {
    return this.httpClient.get<GetRecordsModel<Environment>>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        clusterID +
        '/environments' +
        '?page=' +
        this.defaultPage +
        '&limit=' +
        this.maxItems
    );
  }
  getEnvForClusterOnPage(
    clusterID: string,
    page: number
  ): Observable<GetRecordsModel<Environment>> {
    return this.httpClient.get<GetRecordsModel<Environment>>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        clusterID +
        '/environments' +
        '?page=' +
        page +
        '&limit=' +
        this.maxItems
    );
  }
  getPage(page: number): Observable<GetRecordsModel<Cluster>> {
    return this.httpClient.get<GetRecordsModel<Cluster>>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters?page=' +
        page +
        '&limit=' +
        this.maxItems
    );
  }
  getById(clusterID: string): Observable<Cluster> {
    return this.httpClient.get<Cluster>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        clusterID
    );
  }

  getByInfo(clusterID: string): Observable<Cluster> {
    return this.httpClient.get<Cluster>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' + clusterID + '/info'
    );
  }

  getNamespacesbyCluster(clusterID: string) {
    return this.httpClient.get<Cluster>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        clusterID +
        '/namespaces'
    );
  }

  getByName(clusterName: string): Observable<Cluster> {
    return this.httpClient.get<Cluster>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/name/' +
        clusterName
    );
  }
  delete(id: string): Observable<object> {
    return this.httpClient.delete(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        id,
      this.httpOptions
    );
  }
  create(cluster: Cluster): Observable<object> {
    return this.httpClient.post(
      this.baseURL + this.shareService.organizationSelected.id + '/clusters',
      cluster,
      this.httpOptions
    );
  }
  update(cluster: Cluster): Observable<object> {
    return this.httpClient.put(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        cluster.id,
      cluster,
      this.httpOptions
    );
  }

  reInitCluster(cluster: Cluster, reInitCluster): Observable<object> {
    return this.httpClient.put(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/cloudprovider/' +
        cluster.cloudProviderId +
        '/clusters/' +
        cluster.id +
        '/reinit?re-init-cluster=' +
        reInitCluster,
      cluster,
      this.httpOptions
    );
  }

  //

  getCloudProviders(): Observable<GetRecordsModel<object>> {
    return this.httpClient.get<GetRecordsModel<object>>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/cloudproviders'
    );
  }

  addExistingCluster(form: any): Observable<any> {
    return this.httpClient.post<any>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/cloudprovider/' +
        form.cloudProviderId +
        '/clusters/name/' +
        form.cloudProviderName +
        '/register?region=' +
        form?.region,
      form
    );
  }

  getExistingCluster(cloudProviderId: any): Observable<any> {
    return this.httpClient.get<any>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/cloudprovider/' +
        cloudProviderId +
        '/existing'
    );
  }

  getEnvironmentById(
    projectId: string,
    envId: string
  ): Observable<Environment> {
    return this.httpClient.get<Environment>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/projects/' +
        projectId +
        '/environments/' +
        envId
    );
  }

  getTypeClouds(): Observable<any> {
    return this.httpClient.get<any>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/cloudprovider/supported'
    );
  }

  getClusterRegion(cloudProviderId: string, region: string): Observable<any> {
    return this.httpClient.get<any>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/cloudprovider/' +
        cloudProviderId +
        '/existing?region=' +
        region
    );
  }

  getClusterTypes(): Observable<any> {
    return this.httpClient.get<any>(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/types'
    );
  }

  importNamespace(ns: Environment[], clusterid: string, projectid: string) {
    return this.httpClient.post(
      this.baseURL +
        this.shareService.organizationSelected.id +
        '/clusters/' +
        clusterid +
        '/projects/' +
        projectid +
        '/import/namespaces',
      ns
    );
  }

  deployMonitoring(clusterId: string, data: any): Observable<object> {
    const url = `${this.baseURL}${this.shareService.organizationSelected.id}/clusters/${clusterId}/deploy/observabilities`;
    return this.httpClient.post(url, data, this.httpOptions);
  }
  uninstallMonitoring(clusterId: string): Observable<object> {
    const url = `${this.baseURL}${this.shareService.organizationSelected.id}/clusters/${clusterId}/observabilities`;
    return this.httpClient.delete(url, this.httpOptions);
  }

  getObservability(cluster: Cluster): Observable<object> {
    const url = `${this.baseURL}${this.shareService.organizationSelected.id}/clusters/${cluster.id}/observabilities`;
    return this.httpClient.get(url, this.httpOptions);
  }
}
