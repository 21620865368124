import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/auth/pages/login/services/login.service';
import { ShareService } from 'src/app/data/service/share.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login-apim',
  templateUrl: './login-apim.component.html',
  styleUrls: ['./login-apim.component.scss']
})
export class LoginApimComponent implements OnInit {
  getCode: string = '';
  currentOrganization: any;

  constructor(public loginService: LoginService, public shareService: ShareService) { }

  async getSession() {
    this.currentOrganization = this.shareService.organizationSelected;
    let session = this.shareService.getSession();

    this.loginService.refresh(session.refresh_token).subscribe(
      (newSession) => {
        newSession.currentUser = session.currentUser;
        this.shareService.setSession(newSession);
        this.loginService.SimpleSendTokensToApi().subscribe((data) => {
          this.getCode = data;
          if (data && this.currentOrganization) {
            window.location.href = `${environment.apimRedirect}/?code=${data}&organizationId=${this.currentOrganization.id}`;
          }
        })
      },
      (error) => {
      }
    );
  }

  ngOnInit(): void {
    this.getSession()

  }
}